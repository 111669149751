<template>
  <div class="login-page">
    <v-container>
      <v-row justify="center" class="mt-16">
        <v-col cols="12" lg="6">
          <v-card flat class="rounded mb-5" :loading="isLoading" :disabled="isLoading">
            <div v-if="!success">

              <form @submit.prevent="sendCode" v-if="!otp">
                <v-card-text class="pa-6 pa-lg-12">

                  <div class="mb-2 text-center">
                    <img alt="" src="../assets/logo2.png"/>
                  </div>

                  <div class="text-center mb-5 mt-8">
                    <v-avatar color="gifty" size="70">
                      <v-icon large dark>mdi-account-remove</v-icon>
                    </v-avatar>
                    <h2 class="mt-2">Supprimer mon compte GIFTY !</h2>
                  </div>

                  <p class="text-center mb-5 grey--text text--darken-2">
                    Veuillez confirmer la suppression de votre compte. Cette action est irréversible.
                  </p>

                  <v-alert type="error" v-if="formErrors && formErrors.phone">
                    {{ formErrors.phone.toString() }}
                  </v-alert>

                  <v-text-field outlined
                                type="number"
                                counter="10"
                                dense
                                prepend-inner-icon="mdi-phone"
                                v-model.trim="form.phone_0"
                                label="Numéro de téléphone de votre compte *"
                                placeholder="0xxx xx xx xx"
                  ></v-text-field>

                  <v-textarea
                      outlined
                      dense
                      rows="3"
                      v-model="form.message"
                      label="Message"
                      placeholder="Écrivez votre message ici..."
                  ></v-textarea>

                  <div id="recaptcha-container" :data-sitekey="getSiteKey"></div>
                  <v-btn type="submit"
                         depressed
                         large
                         :loading="isLoading"
                         block
                         dark
                         color="gifty"
                  >
                    <v-icon left>mdi-trash-can</v-icon>
                    Confirmer
                  </v-btn>
                </v-card-text>
              </form>

              <form @submit.prevent="sendRequest" v-if="otp">
                <v-card-text class="pa-6 pa-lg-12">

                  <div class="text-center">
                    <h3 class="mb-3">
                      Confirmer votre numéro de téléphone
                    </h3>

                    <p>
                      Veuillez saisir le code de 6 chiffres
                    </p>
                  </div>

                  <v-alert type="error" v-if="formErrors && formErrors.phone">
                    {{ formErrors.phone.toString() }}
                  </v-alert>

                  <p class="d-flex justify-center">
                    <OtpInput
                        ref="otpInput"
                        input-classes="otp-input"
                        separator=""
                        :num-inputs="6"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        @on-change="handleOnChange"
                        @on-complete="handleOnComplete"
                    />
                  </p>
                  <v-alert color="error" dismissible dense v-if="codeError">
                    Code Incorrect !
                  </v-alert>
                  <div class="text-center">
                    <v-progress-circular color="primary" indeterminate v-if="checkLoading"/>
                  </div>
                </v-card-text>
              </form>

            </div>
            <div v-else class="text-center pa-4">
              <v-icon color="success" size="80">mdi-check-circle-outline</v-icon>
              <p class="mt-3">
                Votre demande a été envoyée avec succès. Votre compte sera supprimé définitivement après 24h.
              </p>
            </div>

          </v-card>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import OtpInput from "@bachdgvn/vue-otp-input";
import {initializeApp} from "firebase/app";
import {getAuth, RecaptchaVerifier, GoogleAuthProvider, signInWithPhoneNumber} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_apiKey,
  authDomain: process.env.VUE_APP_authDomain,
  databaseURL: process.env.VUE_APP_databaseURL,
  projectId: process.env.VUE_APP_projectId,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId,
  appId: process.env.VUE_APP_appId,
  measurementId: process.env.VUE_APP_measurementId,
};

let app;
let auth;
let provider;

export default {
  components: {
    OtpInput,
  },
  data() {
    return {
      otp: false,
      isLoading: false,
      otpError: null,
      form: {
        phone_0: null,
        phone: null,
        message: null,
        token: null,
      },
      formErrors: {},
      checkLoading: false,
      codeError: false,
      success: false,
    };
  },
  methods: {
    sendCode() {
      if (this.form.phone_0) {
        this.form.phone = this.form.phone_0.replace(/^0/, '+213');
      }
      this.initializeCaptcha();
    },
    getSiteKey() {
      return process.env.VUE_APP_recaptcha_sitekey;
    },
    initializeCaptcha() {

      app = initializeApp(firebaseConfig);
      provider = new GoogleAuthProvider();
      auth = getAuth(app);

      this.isLoading = true;
      this.otpError = null;

      window.recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {},
            "expired-callback": () => {},
          },
          auth
      );

      const appVerifier = window.recaptchaVerifier;

      if (this.form.phone_0) {
        this.form.phone = this.form.phone_0.replace(/^0/, '+213');
      }

      signInWithPhoneNumber(auth, this.form.phone, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            this.isLoading = false;
            this.otp = true;
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.code === "auth/too-many-requests") {
              this.otpError = "Trop de tentatives, veuillez réessayer ultérieurement";
            } else {
              this.otpError = "Erreur de vérification. Veuillez réessayer.";
            }
          });
    },
    checkCode(code) {
      this.checkLoading = true;
      window.confirmationResult
          .confirm(code)
          .then((result) => {
            this.checkLoading = false;
            this.codeError = false;
            this.form.token = result.user.accessToken;
          })
          .catch(() => {
            this.checkLoading = false;
            this.codeError = true;
          });
    },
    handleOnComplete(value) {
      this.checkCode(value);
    },
    handleOnChange(value) {
      // Optionally handle change
    },
    sendRequest() {
      this.isLoading = true;
      this.formErrors = {};
      axios.post(process.env.VUE_APP_BASE_URL + 'v1/delete-account-request', {
        token: this.form.token,
        phone: this.form.phone,
        message: this.form.message,
      })
          .then(() => {
            this.isLoading = false;
            this.success = true;
          })
          .catch((err) => {
            console.log(err);
            this.isLoading = false;
            if (err.response && err.response.data && err.response.data.errors) {
              this.formErrors = err.response.data.errors;
            }
          });
    },
  },
};
</script>

<style>
.otp-input {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  font-size: 20px;
  text-align: center;
  border: 2px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.2s ease-in-out;
}

.otp-input:focus {
  border-color: #d60c56;
  box-shadow: 0 0 5px rgba(214, 12, 86, 0.1);
}
</style>
